import React from "react";

const ImageAndTextRight = (props) => {

    return (
        <div className={`kuda-section ${props.className ? props.className : ""}`} >
            <div className="kuda-section--inner flex justify-between align-center mobile-reverse">
                <div className="kuda-section--58">
                    <div className="kuda-section--illustration">
                    {props.illustration}
                    </div>
                </div>
                 <div className="kuda-section--42">
                    <div className="text-center text-lg-left">
                        <h2 className="kuda-section--heading scam-awareness-h2 color-primary title-bottom--spacing">
                            {props.title}
                        </h2>
                        <p className="color-black kuda-section--wrap scam-awareness--section">
                            {props.subtitle}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageAndTextRight;
