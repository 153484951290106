import React from "react";
const CenteredTextSection = (props) => {

    return (
        <div className={`kuda-below--fold ${props.className ? props.className : ""}`} >
            <div className="kuda-career-bottom-margin3  flex justify-center">

                <div className="inApp-contact-ways--wrap flex flex-column mx-auto">

                    <div className="text-center">
                        <h2 className="text-center scam-awareness-h1 color-primary kuda-career-bottom-margin2">
                            {props.heading}
                        </h2>
                        {props.subHeading &&
                            <div className="scam-awareness-text color-black">
                                {props.subHeading}
                            </div>
                        }

                    </div>
                    {props.children &&
                        <div className="kuda-below--inner">
                            {props.children}
                        </div>
                    }
                </div>

            </div>

        </div>
    )
}

export default CenteredTextSection;
