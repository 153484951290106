import React from "react";

const Header = (props) => {

    return (
        <div className="kuda-entry--fold  justify-center">
        <div className="kuda-section--inner career-container-width">
            <div className="text-center justify-between flex flex-column scam-awareness-header">

                <div className="flex flex-column justify-center">

                    <h1 className="mx-auto scam-awareness-title kuda-main--heading text-xlbold color-primary">
                        {props.title}
                    </h1>
                    <p className="mx-auto scam-awareness-subtext text-semi-bold color-black">
                        {props.subtitle}
                    </p>

                </div>

            </div>
        </div>
    </div>

    )
}

export default Header;
