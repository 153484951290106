import React from "react";
import Twitter from "../../../../../assets/img/twitter-light.svg"
import Instagram from "../../../../../assets/img/instagram-light.svg"
import Facebook from "../../../../../assets/img/facebook-light.svg"
import Tiktok from "../../../../../assets/img/tiktok-light.svg"
import {Img} from 'react-image';

const SocialMedialLinks = () => {
    const socialLinks = [
        {
            icon: Twitter,
            linkContent: (<div className="flex flex-row justify-start color-primary">
                <a
                    to="https://twitter.com/joinkuda"
                    className="cta_button"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                >
                    @joinkuda
                </a>
                <span className="mx-1 color-black">and</span>
                <a
                    to="https://twitter.com/kudahelp_ng"
                    className="cta_button"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                > @kudahelp_ng
                </a>
            </div>)
        },
        {
            icon: Instagram,
            linkContent: (<a
                to="https://www.instagram.com/joinkuda/"
                className="color-primary cta_button"
                rel="nofollow noopener noreferrer"
                target="_blank"
            >
                @joinkuda
            </a>)
        },
        {
            icon: Facebook,
            linkContent: (<a
                to="https://www.facebook.com/joinkuda/"
                className="color-primary cta_button"
                rel="nofollow noopener noreferrer"
                target="_blank"
            >
                facebook.com/joinkuda
            </a>)
        },
        {
            icon: Tiktok,
            linkContent: (<a
                to="https://www.tiktok.com/@joinkuda"
                className="color-primary cta_button"
                rel="nofollow noopener noreferrer"
                target="_blank"
            >
                @joinkuda
            </a>)
        }
    ]

    return (
        <div className="flex flex-column mb-4" >
            {socialLinks.map((item, i) =>
                <div key={i} className="flex flex-column justify-between">
                    <div className="mt-2 flex align-center">
                        <Img src={item.icon} alt="kuda Microfinance Bank Testimonials" className="kuda-icon mr-2" />
                        <div className="scam-awareness-text align-center">
                            {item.linkContent}
                        </div>
                    </div>

                </div>)
            }
            <div className="scam-awareness-text color-black mt-4">
                Please, look out for spam social media accounts that pretend to be Kuda.
            </div>
        </div>
    )
}

export default SocialMedialLinks;
