import React from "react";

const ScamAwarenessActions = (props) => {
    return (
        <div className={`kuda-section ${props.className ? props.className : ""}`} >
            <div className="kuda-career-bottom-margin3  flex justify-center">

                <div className="flex flex-column">

                    <div className="text-center inApp-contact-ways--wrap  mx-auto">
                        <h2 className="kuda-section--heading text-center scam-awareness-h2 color-primary kuda-career-bottom-margin2">
                        What to do if you suspect that you’ve been scammed
                        </h2>

                    </div>
                    <div className="kuda-below--inner isCentered scam-actions color-black leading-md-6 f-md-16 text-center flex flex-column mx-auto ">
                        <div>If you’re not sure about any transaction on your account or you suspect you have been scammed, please call 0700022555832 or send an email to fraud@kuda.com or help@kuda.com immediately</div>
                        <div className="mt-3"> Don't post details of suspected scams or suspicious transactions on social media because we will not be able to respond to you there for security reasons. Instead of posting on social media, reach out to us directly by phone or email, or chat with us on your Kuda app.
                        </div>
                        <div className="mt-3"> If you report a suspicious transaction or a suspected scam, we may restrict your account temporarily to protect your money while we investigate your report</div>

                    </div>
                </div>

            </div>

        </div>
    )
}

export default ScamAwarenessActions;
