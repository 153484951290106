import React from "react"
import ScamPage from "../components/body/pages/scam"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Scam = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/scam-awareness/"}
      title="Scam & Fraud Awareness | Staying Safe Online | Kuda"
      description="Awareness is key to staying safe online and protecting your money & identity. Read here for all you need to know about keeping your Kuda account safe."
    />
    <ScamPage />
  </Layout>
)

export default Scam
