import React from "react";
import ScamSectionTitle from "./scamSectionTitle"
const NumberedSection = (props) => {
    return (
        <div className="flex flex-column mb-4">
            <ScamSectionTitle title={props.title} subText={props.subtitle}
            />
            {
                props.listItems?.map((item, i) => {
                    return (<div className="flex flex-column title-bottom--spacing" key={i}>
                        <h5 className="color-black text-xbold mb-2 scam-awareness-h5">{item.title}</h5>
                        <span className="scam-awareness-text color-black">{item.subText}</span>
                    </div>)
                })
            }


        </div>
    )
}

export default NumberedSection;
