import React from "react";

const ScamSectionTitle = (props) => {
    return (
      
        <div className=  {`flex flex-column contact-title ${props.className ? props.className : "title-bottom--spacing"}`}>
            <div className="color-primary scam-awareness-h3 title-bottom--spacing">{props.title}</div>
            {props.subText &&
                <div className="scam-awareness-text color-black">
                    {props.subText}    
                </div>
            }

        </div>
    )
}
export default ScamSectionTitle