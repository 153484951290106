import React, { useEffect, Fragment } from "react"
import { scrollToElement } from "../../../utility/utils"
import Header from "./components/header"
import NumberedSection from "./components/numberedSection"
import DataPrivacyIllustration from "../../../../assets/img/kuda-awareness-scam.svg"
import ScamAlertIllustration from "../../../../assets/img/common-scam-alert.svg"
import IdentifyScamIllustration from "../../../../assets/img/kuda-scam-identify.svg"

import ScamAwarenessActions from "./components/scamAwarenessActions"
import SocialMedialLinks from "./components/socialMediaContent"
import ScamSectionTitle from "./components/scamSectionTitle"
import ImageAndTextRight from "./components/imageAndTextRight"
import ScamAlertSection from "./components/imageAndTextLeft"
import CenteredTextSection from "./components/centeredTextSection"

const ScamPage = () => {
  const emailContent = {
    headerTitle: "Emails",
    subTitle: `All emails from us will always come from addresses that end in ‘kuda.com’ e.g. kuda.com, news.kuda.com and alerts.kuda.com. We may send the following messages to the email address linked to your Kuda profile:`,
    listItems: [
      {
        title: "1. Transaction alerts",
        subText: `These include debit and credit alerts, savings alerts, gift card codes, electricity meter tokens and stock trading alerts`
      },
      {
        title: "2. Announcements",
        subText: `These include app update announcements, promotional messages, new feature alerts, downtime updates and maintenance updates`
      },
      {
        title: "3. Device change",
        subText: `We sent you this message as an update whenever you sign in to your Kuda account on a new device.`
      },
      {
        title: "4. Password change",
        subText: `We send you this when you request a password change on your Kuda app or app.kuda.com.`
      },
      {
        title: "5. Account statement",
        subText: `We send you your account statement as a spreadsheet or a PDF document whenever you request it on your Kuda app.`
      },
    ]

  }
  const smsContent = {
    headerTitle: "SMS",
    listItems: [
      {
        title: "1. Phone number confirmation",
        subText: `We send you a code to confirm that the phone number you're adding to your Kuda profile is yours.`
      },
      {
        title: "2. BVN confirmation",
        subText: `We send you a code to confirm that the BVN (Bank Verification Number) you're linking to your Kuda profile is yours.`
      },
      {
        title: "3. Card payment confirmation",
        subText: `We send you a code to confirm any card payment you’re trying to make.`
      },
      {
        title: "4. Overdraft marketing and repayment reminders",
        subText: `We may send you messages if you’re eligible to take an overdraft, and messages reminding you about repayment if you take an overdraft.`
      },
      {
        title: "",
        subText: `Please, note that every SMS we send you will have a clear and consistent sender name - Kuda.`
      }
    ]
  }
  const entryContent = {
    title: "Scam And Fraud Awareness",
    subtitle: "Awareness helps you protect your identity and your money, and stay safe online. Keep reading to learn how to spot scams and what to do if you suspect that you've been scammed."
  }
  const phoneContent =
  {
    headerTitle: "Phone Calls",
    subTitle: (
      <span>
        <p className="text-normal scam-awareness-text  color-black title-bottom--spacing ">
          We may call you to follow up on a complaint or transaction dispute, to give you important information about your Kuda account, to share a survey with you, or just to check up on you.

        </p>
        <p className="scam-awareness-text text-normal color-black">
          If you receive a call from us, we'll tell you who is calling and why we're calling, and we'll never ask you for confidential account details like your password, passcode, transaction PIN, card CVV (the three-digit number on the back of your debit card) or your Pay ID.

        </p>
      </span>)
  }
  const privateDataMessage = {
    title: "Personal information you must never share",
    subtitle: (
      <span className="career-message-margin-bottom">
        <ul className="diclaimer-list leading-md-9 f-md-16">
          <li className="mb-2">Your Kuda password, passcode and transaction PIN.</li>
          <li className="mb-2">Your card PIN and CVV (the three-digit number on the back of your debit card).
          </li>
          <li>Your Pay ID.
          </li>
        </ul>
      </span>
    ),
    illustration: <img src={DataPrivacyIllustration} />,
  }
  const commonScams = {
    title: "Common payment scams",
    subtitle: (
      <span className="career-message-margin-bottom">
        <ul className="diclaimer-list text-left pl-0  leading-md-6 f-md-16">
          <li className="mb-2">A text or WhatsApp message, a tweet or an email warning that your account will be closed unless you click or tap a link to update your account information.</li>
          <li className="mb-2">A text or WhatsApp message, an email or a phone call asks you to pay to get a Kuda Overdraft.</li>
          <li className="mb-2">A text or WhatsApp message, a tweet, an email or a phone call asks you to share confidential account details like your password, passcode, card PIN, CVV or Pay ID.</li>
          <li>A merchant or vendor you’ve never done business with asks you to pay before receiving your order.</li>
        </ul>
      </span>
    ),
    illustration: <img src={ScamAlertIllustration} />,
  }
  const identifyScam = {
    title: "How to identify a scam",
    subtitle: (
      <span className="career-message-margin-bottom">
        <ol className="ordered-list text-left pl-0  leading-md-6 f-md-16">
          <li className="mb-2">Spelling errors in a text or WhatsApp message or email claiming to be from your bank.</li>
          <li className="mb-2">A strange link in a text or WhatsApp message or email eg. nameofbank.agent.direct-link.com.</li>
          <li className="mb-2">The sender ID of a text message is a random phone number (instead of your bank’s name).</li>
          <li>A text or WhatsApp message, email, phone call or social media post urging you to make a payment or investment quickly.</li>
        </ol>
      </span>
    ),
    illustration: <img src={IdentifyScamIllustration} />,
  }
  const socialMediaContent = {
    headerTitle: "Social media",
    subtitle: `We may send you a message on social media to follow up on a question, complaint or transaction dispute. These are our official social media accounts:`
  }
  const spotScam = {
    heading: "How to spot a scam email",
    subHeading: (
      <div className="flex flex-column text-center leading-md-6 f-md-16">
        <div>Generally, any text message, email or phone call about your Kuda account that you cannot confirm is from us is definitely dangerous and must be ignored. </div>
        <div> If you are in doubt about any text message, email or phone call, please call 0700022555832 or send an email to fraud@kuda.com or help@kuda.com before taking any other action
        </div>
      </div>
    )
  }

  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <Header title={entryContent.title} subtitle={entryContent.subtitle} />

      <ImageAndTextRight
        title={privateDataMessage.title}
        subtitle={privateDataMessage.subtitle}
        illustration={privateDataMessage.illustration}
        className="mt-0"
      />
      <CenteredTextSection
        heading="How we may contact you off your Kuda app"
        className="kuda-section">
        <NumberedSection
          title={emailContent.headerTitle} subtitle={emailContent.subTitle}
          listItems={emailContent.listItems}
        />
        <NumberedSection
          title={smsContent.headerTitle} subtitle={smsContent.subTitle}
          listItems={smsContent.listItems}
        />
        <ScamSectionTitle title={phoneContent.headerTitle} subText={phoneContent.subTitle} className="mb-4"
        />
        <ScamSectionTitle title={socialMediaContent.headerTitle} subText={socialMediaContent.subtitle} className="mb-0"
        />
        <SocialMedialLinks />
        <ScamSectionTitle 
        title={"WhatsApp Messages"} 
        subText={"We sometimes send overdraft repayment reminders through WhatsApp, but we never use WhatsApp for customer support. There is no public Kuda WhatsApp number."} className="mb-0"
        />
      </CenteredTextSection>


      <ScamAlertSection
        title={commonScams.title}
        subtitle={commonScams.subtitle}
        illustration={commonScams.illustration}
      />
      <CenteredTextSection
        heading={spotScam.heading}
        subHeading={spotScam.subHeading}
        className="kuda-section">     

      </CenteredTextSection>

      <ImageAndTextRight
        title={identifyScam.title}
        subtitle={identifyScam.subtitle}
        illustration={identifyScam.illustration}
      />

      <ScamAwarenessActions/>
    </Fragment>
  )
}

export default ScamPage
